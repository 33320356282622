body {
  overflow-x: hidden;
}

.stage > h1 + div {
  max-width: 55rem;
  width: 100%;
}

.hero\:max {
  max-height: max(800px, 80vh);
}

.hero-video {
  padding: 0;
  width: 700px;
  height: 395px;
  position: relative;
  overflow: hidden;
  border: none;
}

.hero-video iframe {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 101%;
  height: 101%;
  border-radius: 12px;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .hero-video {
    width: 90vw;
    height: 50vw;
  }
}

.engaging-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.engaging-cards > div {
  border-left-width: 17px;
  border-left-style: solid;
  margin: 1rem;
  flex: 1 320px;
}

.engaging-cards > div .img-container {
  height: 50px;
  display: flex;
  margin-bottom: 0.5rem;
}

.tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tiles > div {
  width: calc(1/2*100% - (1 - 1/2)*1.5rem);
  margin-bottom: 1.5rem;
}

@media (max-width: 568px) {
  .tiles > div {
    width: 100%;
  }
}

.everything-you-need {
  align-items: center;
}

.everything-you-need > div:nth-child(1) > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 300px;
  margin-bottom: 2rem;
}

.everything-you-need > div:nth-child(1) > div p {
  margin-top: 0;
  margin-left: 1rem;
}

.everything-you-need > div:nth-child(2) {
  margin-bottom: 2rem;
}

.everything-you-need > div:nth-child(2) img {
  width: 100%;
  max-width: 500px;
}

.bm-content-info {
  background: #F2F2F3;
  box-shadow: 0 15px 46px 0 rgba(0, 0, 0, 0.41);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .bm-content-info {
    flex-direction: column;
  }
}

.bm-content-info > div:nth-of-type(1) {
  background: #4475C5;
  flex: 1;
  padding: 1rem 1.25rem;
}

@media (max-width: 768px) {
  .bm-content-info > div:nth-of-type(1) {
    flex: auto;
  }
}

.bm-content-info > div:nth-of-type(1) p:first-child {
  margin-top: 0;
}

.bm-content-info > div:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  flex: 3 20rem;
}

@media (max-width: 768px) {
  .bm-content-info > div:nth-of-type(2) {
    flex: auto;
  }
}

.bm-content-info > div:nth-of-type(2) > ul {
  display: flex;
  flex: 3 20rem;
  flex-wrap: wrap;
  padding: 1rem 1.25rem 0;
}

.bm-content-info > div:nth-of-type(2) > ul li {
  flex: 1 14rem;
  text-align: left;
  list-style: none;
}

.bm-content-info > div:nth-of-type(2) > a {
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 1.25rem;
  margin: 0.5rem;
  border-radius: 5px;
}

.bm-content-info > div:nth-of-type(2) > a small {
  font-weight: normal;
  font-size: 14px;
  text-decoration: line-through double;
}

.tiles.who-this-is-for > div > div {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.mobile-who-this-is-for-tile {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
}

.mobile-who-this-is-for-tile h3 + p {
  margin-top: 0.25rem;
}

.mobile-who-this-is-for-tile > div:nth-child(1) {
  background: white;
  border-radius: 100%;
  color: #1E282C;
  font-size: 18px;
  font-weight: bold;
  width: 1.6em;
  height: 1.6em;
  flex-shrink: 0;
  text-align: center;
  line-height: 1.6em;
  margin-right: 1rem;
}

.avatar {
  border-radius: 100%;
  overflow: hidden;
  width: 6rem;
  height: 6rem;
}

.avatar img {
  width: 100%;
}

.stat-card {
  display: flex;
  flex-direction: column;
}

.stat-card p {
  flex: 1;
}

.ninja .to-edges {
  display: flex;
}

@media (max-width: 568px) {
  .ninja .to-edges {
    flex-direction: column;
  }
}

.ninja .to-edges > div:nth-of-type(1) {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 568px) {
  .ninja .to-edges > div:nth-of-type(1) {
    flex-direction: row;
    padding: 2rem;
    font-size: 18px;
  }
}

.ninja .to-edges > div:nth-of-type(2) {
  flex: 2;
  padding: 2rem 3rem;
}

.unboring-topic {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.unboring-topic .separator {
  width: 3rem;
  height: 3rem;
}

@media (min-width: 800px) {
  .unboring-topic:nth-of-type(2n + 1) {
    flex-direction: row-reverse;
  }
  .unboring-topic > *:not(.separator) {
    flex: 1;
  }
}

@media (max-width: 800px) {
  .unboring-topic {
    flex-direction: column;
  }
  .unboring-topic .card\:video {
    width: 75%;
  }
}

@media (max-width: 500px) {
  .unboring-topic .card\:video {
    width: 100%;
  }
}
