@import "../_variables";
@import "../mixins/_breakpoint.mixin";

body {
	overflow-x: hidden;
}

.stage > h1 + div {
	max-width: 55rem;
	width: 100%;
}

.hero\:max {
	max-height: unquote("max(800px, 80vh)");
}

.hero-video {
	padding: 0;
	width: 700px;
	height: 395px;
	position: relative;
	overflow: hidden;
	border: none;

	iframe {
		position: absolute;
		top: -1px;
		left: -1px;
		width: 101%;
		height: 101%;
		border-radius: 12px;
		overflow: hidden;
	}

	@include breakpoint(constrained-width) {
		width: 90vw;
		height: 50vw;
	}
}

.engaging-cards {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	& > div {
		border-left-width: 17px;
		border-left-style: solid;
		margin: map-get($spacings, 4);
		flex: 1 320px;

		.img-container {
			height: 50px;
			display: flex;
			margin-bottom: map-get($spacings, 2);
		}
	}
}

.tiles {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	& > div {
		$margin: map-get($spacings, 6);
		width: calc(1/2*100% - (1 - 1/2)*#{$margin});
		margin-bottom: $margin;

		@include breakpoint(phone-width, -200px) {
			width: 100%;
		}
	}
}

.everything-you-need {
	align-items: center;

	& > div:nth-child(1) {
		& > div {
			display: flex;
			flex-direction: row;
			align-items: center;
			max-width: 300px;
			margin-bottom: map-get($spacings, 8);

			p {
				margin-top: 0;
				margin-left: map-get($spacings, 4);
			}
		}
	}

	& > div:nth-child(2) {
		margin-bottom: map-get($spacings, 8);

		img {
			width: 100%;
			max-width: 500px;
		}
	}
}

.bm-content-info {
	background: #F2F2F3;
	box-shadow: 0 15px 46px 0 rgba(0,0,0,0.41);
	border-radius: 8px;
	overflow: hidden;
	display: flex;
	flex-direction: row;

	@include breakpoint(phone-width) {
		flex-direction: column;
	}

	& > div:nth-of-type(1) {
		background: #4475C5;
		flex: 1;
		@include breakpoint(phone-width) { flex: auto; }
		padding: map-get($spacings, 4) map-get($spacings, 5);
		p:first-child {
			margin-top: 0;
		}
	}

	& > div:nth-of-type(2) {
		display: flex;
		flex-direction: row;
		flex: 3 20rem;
		@include breakpoint(phone-width) { flex: auto; }

		& > ul {
			display: flex;
			flex: 3 20rem;
			flex-wrap: wrap;
			padding: map-get($spacings, 4) map-get($spacings, 5) 0;


			li {
				flex: 1 14rem;
				text-align: left;
				list-style: none;
			}
		}

		& > a {
			color: white;
			font-weight: bold;
			display: flex;
			flex-direction: column;
			font-size: map-get($font-sizes, lg);
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: map-get($spacings, 4) map-get($spacings, 5);
			margin: map-get($spacings, 2);
			border-radius: 5px;

			small {
				font-weight: normal;
				font-size: map-get($font-sizes, sm);
				text-decoration: line-through double;
			}
		}
	}
}

.tiles.who-this-is-for {
	& > div > div {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
	}
}

.mobile-who-this-is-for-tile {
	display: flex;
	align-items: baseline;
	margin-bottom: map-get($spacings, 4);

	h3 + p { margin-top: map-get($spacings, 1); }

	& > div:nth-child(1) {
		$diameter: 1.6em;
		background: white;
		border-radius: 100%;
		color: map-get($colors, neutral-700);
		font-size: map-get($font-sizes, lg);
		font-weight: bold;
		width: $diameter;
		height: $diameter;
		flex-shrink: 0;
		text-align: center;
		line-height: $diameter;
		margin-right: map-get($spacings, 4);
	}
}

.avatar {
	border-radius: 100%;
	overflow: hidden;
	width: 6rem;
	height: 6rem;

	img {
		width: 100%;
	}
}

.stat-card {
	display: flex;
	flex-direction: column;

	p { flex: 1; }
}

.ninja .to-edges {
	display: flex;

	@include breakpoint(phone-width, -200) {
		flex-direction: column;
	}

	& > div:nth-of-type(1) {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@include breakpoint(phone-width, -200) {
			flex-direction: row;
			padding: map-get($spacings, 8);
			font-size: map-get($font-sizes, lg);
		}
	}

	& > div:nth-of-type(2) {
		flex: 2;
		padding: map-get($spacings, 8) map-get($spacings, 12);
	}
}

.unboring-topic {
	display: flex;
	align-items: center;
	margin-bottom: map-get($spacings, 8);

	.separator {
		width: map-get($spacings, 12);
		height: map-get($spacings, 12);
	}

	@include breakpoint(unconstrained-width, -200) {
		&:nth-of-type(2n + 1) {
			flex-direction: row-reverse;
		}

		& > *:not(.separator) {
			flex: 1;
		}
	}
	@include breakpoint(constrained-width, -200) {
		flex-direction: column;

		.card\:video {
			width: 75%;
		}
	}
	@include breakpoint(constrained-width, -500) {
		.card\:video {
			width: 100%;
		}
	}
}
