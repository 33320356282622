@import '../_variables';

@mixin breakpoint($point, $addition: 0) {
	@if $point == constrained-width {
		@media (max-width: $max-width + $addition) {
			@content;
		}
	}
	@if $point == unconstrained-width {
		@media (min-width: $max-width + $addition) {
			@content;
		}
	}
	@if $point == phone-width {
		@media (max-width: 768px + $addition) {
			@content;
		}
	}
	@if $point == not-phone-width {
		@media (min-width: 769px + $addition) {
			@content;
		}
	}
}

